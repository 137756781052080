<template>
  <router-view/>
</template>

<script>
import M from 'materialize-css'
export default{
    beforeMount(){
      this.checkForThemes();
    },
    data(){
      return{
        themePreset: localStorage.getItem('theme'),
      }
    },
    methods: {
      toggle(payload){
        this.isDarkMode = payload;
        document.documentElement.className = payload;
        localStorage.setItem('theme', payload);
      },
      checkForThemes(){
        if (this.themePreset === "" || this.themePreset === null || this.themePreset === 0) {
          localStorage.setItem('theme', 'false');
        }
      }
    },
    mounted(){
      M.AutoInit();
      this.toggle(JSON.parse(localStorage.getItem('theme')));
    },
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Montserrat');
  @import '@/styles/dark.css';
  @import '@/styles/light.css';

  html{
    font-family: Montserrat !important;
    scroll-behavior: smooth;
    transition: all 0.5s ease-in-out;
    -webkit-font-smoothing: antialiased;
  }

  body{
    background-color: var(--bg);
    color: var(--text);
  }

  input{
    color: var(--text);
  }

  h5{
    color: var(--text);
  }

  p{
    color: var(--text);
  }

  select{
    color: var(--text);
  }

  option{
    color: var(--text);
  }

  .spec-text {
    color: var(--bg-revese) !important;
  }

  select{
    background-color: var(--bg-card) !important;
    border: none;
  }

  .card-panel{
    background-color: var(--bg-card) !important;
  }

  .card{
    background-color: var(--bg-card) !important;
  }

  .chip {
    background-color: var(--bg-chip) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipses;
  }

  .material-icons{
    color: var(--bg-white) !important;
  }

  .sidenav{
    background-color: var(--bg-sidenav) !important;
  }
  .modal{
    background-color: var(--bg-sidenav) !important;
    border-radius: 7px;
  }
  .modal-footer{
    background-color: var(--bg-sidenav) !important;
  }

  .container-fluid{
    margin-right: 7px;
    margin-left: 7px;
  }
</style>
