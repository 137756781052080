<template>
    <div v-if="pollsLoader" class="center">
        <loader-primary />
    </div>
    <div v-else>
        <div v-if="polls.length">
            <div class="row">
                <div v-for="poll in polls" :key="poll">
                    <div class="col s12 m6 l4">
                        <div class="card hoverable rounded">
                            <div class="card-content center">
                                <h6 class="blue-text text-darken-1 truncate">{{ poll.p }}</h6>
                                <br>
                                <section>
                                    <div class="row">
                                        <div class="col s6 m6 l6">
                                            <testing-component :selectedPoll="poll.z" :selectedPollName="poll.p" :selectedInterval="return_verification_interval"/>
                                        </div>
                                        <div class="col s6 m6 l6">
                                            <result-component :selectedPoll="poll.z" :selectedPollName="poll.p" :selectedInterval="return_result_interval"/>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                    <info-component />
                </div>
            </div>
        </div>
        <div v-else>
            <p class="center red-text text-darken-4">{{ pollsMessage }}</p>
        </div>
    </div>
</template>

<script>
import LoaderPrimary from './LoaderPrimary.vue';
import TestingComponent from './TestingComponent.vue';
import ResultComponent from './ResultComponent.vue';
import axios from 'axios';
import InfoComponent from './InfoComponent.vue';
export default {
    props: {
        return_key: {
            type: String,
            required: true,
        },
        return_verification_interval: {
            type: Number,
            required: true,
        },
        return_result_interval: {
            type: Number,
            required: true,
        }
    },
    data(){
        return{
            pollsLoader: true,
            pollsMessage: null,
            polls:[],
            pageKey: 'mIchAEl',
        }
    },
    methods: {
        async getPolls(){
            let data = new FormData();
            data.append('a', this.pageKey);
            data.append('b', this.return_key);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/SvnYBAbxslJHABV/hoagie', data)
                let returnCondition = response.data.k
                let returnMessage = response.data.l

                if (returnCondition === 1) {
                    this.pollsLoader = false
                    this.pollsMessage = null
                    this.polls = response.data.m
                } else {
                    this.pollsLoader = false
                    this.pollsMessage = returnMessage
                    this.polls = []
                }
            } catch (error) {
                this.pollsLoader = false
                this.pollsMessage = 'Network Error. Try Again'
                this.polls = []
                //console.log(error);
            }
        },
    },
    mounted(){
        this.getPolls();
    },
    components: { LoaderPrimary, TestingComponent, ResultComponent, InfoComponent, },
}
</script>
<style scoped>
    .rounded{
        border-radius: 14px;
    }
</style>