<template>
    <nav class="transparent z-depth-0" style="margin-bottom: 14px;">
        <div class="nav-wrapper container-sm">
            <router-link :to="{name: 'home'}" class="brand-logo red-text text-darken-1 left">
                <img src="../assets/logo.png" class="place"/>
            </router-link>

            <ul class="right">
                <li class="center">
                    <div v-if="isDarkMode === false">
                        <span><i @click.prevent="toggle(!isDarkMode)" class="material-icons blue-text text-darken-1" style="cursor: pointer;">light_mode</i></span>
                    </div>
                    <div v-else>
                        <span><i @click.prevent="toggle(!isDarkMode)" class="material-icons blue-text text-darken-1" style="cursor: pointer;">dark_mode</i></span>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    data(){
        return{
            isDarkMode: false,
        }
    },
    methods: {
        toggle(payload){
            this.isDarkMode = payload;
            document.documentElement.className = payload;
            localStorage.setItem('theme', payload);
        }
    }
}
</script>

<style scoped>
    .place{
        width: 49px; 
        height: 49px; 
        margin-left: 5px; 
        margin-top: 10px;
    }

    .container-sm{
        margin-right: 5px; 
        margin-left: 5px;
    }
</style>