<template>
    <p class="center red-text text-darken-1">Error 404. Page Not Found</p>
</template>

<script>
import router from '@/router'
export default {
    mounted(){
        router.push({name: 'home'});
    }
}
</script>

<style>

</style>