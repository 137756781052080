<template>
  <navbar-primary />
  <div class="container-fluid">
    <div v-if="pageLoader" class="center">
      <loader-primary />
    </div>
    <div v-else>
      <div>
        <blockquote>The National Tallying Center</blockquote>
      </div>
      <div v-if="pageStatus === 1">
        <polls-component :return_key="returnKey" :return_verification_interval="returnVerInterval" :return_result_interval="returnResInterval"/>
      </div>
      <div v-else>
        <p class="center red-text text-darken-1">{{ pageMessage }}</p>
      </div>
    </div>
  </div>
  <footer-primary />
</template>

<script>
import NavbarPrimary from '@/components/NavbarPrimary.vue'
import FooterPrimary from '@/components/FooterPrimary.vue';
import LoaderPrimary from '@/components/LoaderPrimary.vue';
import PollsComponent from '@/components/PollsComponent.vue';
import axios from 'axios'

export default {
  data(){
    return{
      pageLoader: true,
      pageMessage: null,
      pageStatus: 0,
      pageKey: 'mIchAEl',
      returnKey: null,
      returnVerInterval: null,
      returnResInterval: null,
      returnCheckInterval: null,
    }
  },
  methods: {
    async checkPageStatus(){
      let data = new FormData();
      data.append('x', this.pageKey);

      try {
        const response = await axios.post('https://a.ivoting.co.ke/SvnYBAbxslJHABV/nigel', data)
        let returnCondition = response.data.a
        let returnMessage = response.data.b

        if (returnCondition === 1) {
          this.pageLoader = false
          this.pageMessage = null
          this.pageStatus = 1
          this.returnKey = response.data.c
          this.returnVerInterval = response.data.d
          this.returnResInterval = response.data.e
          this.returnCheckInterval = response.data.f

          this.hotReload();
        } else {
          this.pageLoader = false
          this.pageMessage = returnMessage
          this.pageStatus = 0
        }
      } catch (error) {
        this.pageLoader = false
        this.pageMessage = 'Network Error. Try Again'
        this.pageStatus = 0
        //console.log(error);
      }
    },
    hotReload(){
      setInterval( async () => {
          let data = new FormData()
          data.append('y', this.pageKey);

          try {
            const response = await axios.post('https://a.ivoting.co.ke/SvnYBAbxslJHABV/stickybeard', data)
            let returnCondition = response.data.c

            if (returnCondition === 1) {
              this.checkPageStatus();
            }
          } catch (error) {
            this.checkPageStatus();
            //console.log(error);
          }
      }, this.returnCheckInterval);
    },

  },
  mounted(){
    this.checkPageStatus();
  },
  created(){
    document.title = this.$route.meta.title;
  },
  components: { NavbarPrimary, FooterPrimary, LoaderPrimary, PollsComponent },
}
</script>
<style scoped>
  .rounded{
    border-radius: 14px;
  }

  .full{
    width: 100%
  }

  .text-normal{
    text-transform: capitalize !important;
  }
</style>
