<template>
    <section>
        <div v-if="verLoader" class="center">
            <loader-primary />
        </div>
        <div v-else>
            <div v-if="verStats.length">
                <div v-if="verType === 1">
                    <div v-for="verStat in verStats" :key="verStat">
                        <p class="card-title"><small>{{ verStat.b }}</small> / <span>{{ verStat.c }}</span></p>
                        <p>{{ (((+verStat.b) / verStat.c) * 100).toFixed(2) }} <small>%</small></p>
                        <div>
                            <div class="progress">
                                <div v-if="(((+verStat.b) / verStat.c) * 100).toFixed(2) < 25">
                                    <div class="determinate red darken-4" :style="{width: ((((+verStat.b) / verStat.c) * 100).toFixed(2)+'%')}"></div>
                                </div>
                                <div v-else-if="(((+verStat.b) / verStat.c) * 100).toFixed(2) >= 25 && (((+verStat.b) / verStat.c) * 100).toFixed(2) < 50">
                                    <div class="determinate yellow lighten-1" :style="{width: ((((+verStat.b) / verStat.c) * 100).toFixed(2)+'%')}"></div>
                                </div>
                                <div v-else-if="(((+verStat.b) / verStat.c) * 100).toFixed(2) >= 50 && (((+verStat.b) / verStat.c) * 100).toFixed(2) < 75">
                                    <div class="determinate blue darken-1" :style="{width: ((((+verStat.b) / verStat.c) * 100).toFixed(2)+'%')}"></div>
                                </div>
                                <div v-else>
                                    <div class="determinate teal darken-1" :style="{width: ((((+verStat.b) / verStat.c) * 100).toFixed(2)+'%')}"></div>
                                </div>
                            </div>
                        </div>
                        <div class="center">
                            <div v-if="verToday === 0">
                                <p><small><i class="material-icons red-text text-darken-1">arrow_drop_down</i> {{ verToday }}</small></p>
                            </div>
                            <div v-else>
                                <p><small><i class="material-icons green-text text-darken-1">arrow_drop_up</i> {{ verToday }}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-for="verStat in verStats" :key="verStat">
                        <p><span class="card-title">{{ verStat.b }}</span> <small>voters</small></p>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ verMessage }}</p>
            </div>
        </div>
    </section>
</template>

<script>
import M from 'materialize-css';
import LoaderPrimary from './LoaderPrimary.vue';
import axios from 'axios';
export default {
    props: {
        selectedPoll: {
            type: String,
            required: true,
        },
        selectedPollName: {
            type: String,
            required: true,
        },
        selectedInterval: {
            type: Number,
            required: true,
        }
    },
    data(){
        return{
            verLoader: true,
            verMessage: null,
            verStats: [],
            verType: null,
            modal_trigger: '#'+this.selectedPoll,
            verToday: null,
        }
    },
    methods: {
        hotReload(){
            setInterval( async () => {
                let data = new FormData()
                data.append('x', this.selectedPoll);

                try {
                    const response = await axios.post('https://a.ivoting.co.ke/SvnYBAbxslJHABV/abigail', data)
                    let returnCondition = response.data.g
                    let returnMessage = response.data.h

                    if (returnCondition === 1) {
                    this.verLoader = false
                        this.verMessage = null
                        this.verType = response.data.i
                        this.verStats = response.data.j
                        this.verToday = response.data.k
                    } else {
                        this.verLoader = false
                        this.verMessage = returnMessage
                        this.verStats = []
                    }
                } catch (error) {
                    this.verLoader = false
                    this.verStats = []
                    this.verMessage = 'Network Error. Try Again'
                    //console.log(error);
                }
            }, this.selectedInterval);
        },

        async getVerificationData(){
            let data = new FormData()
            data.append('x', this.selectedPoll);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/SvnYBAbxslJHABV/abigail', data)
                let returnCondition = response.data.g
                let returnMessage = response.data.h

                if (returnCondition === 1) {
                    this.verLoader = false
                    this.verMessage = null
                    this.verType = response.data.i
                    this.verStats = response.data.j
                    this.verToday = response.data.k
                    this.hotReload();
                } else {
                    this.verLoader = false
                    this.verMessage = returnMessage
                    this.verStats = []
                }
            } catch (error) {
                this.verLoader = false
                this.verStats = []
                this.verMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
    },
    mounted(){
        M.AutoInit();
        this.getVerificationData();
    },
    components: { LoaderPrimary,  }
}
</script>

<style>

</style>