import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Verification from '../views/VerificationOverview'
import Error404 from '../views/Error404View.vue'
import Result from '../views/ResultsOverview.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'Stats - iVoting' },
  },
  // {
  //   path: '/#/:pollID',
  //   name: 'ver',
  //   props: true,
  //   component: Verification,
  //   meta: { title: 'Voter Overview - iVoting' },
  // },
  // {
  //   path: '/#/#/:resID',
  //   name: 'res',
  //   props: true,
  //   component: Result,
  //   meta: { title: 'Results Overview - iVoting' },
  // },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: Error404,
    meta: { title: 'Error 404 - iVoting' }
  },
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
