<template>
    <div class="fixed-action-btn">
        <a class="btn-floating btn-large blue darken-1 modal-trigger" href="#stats-info">
            <i class="large material-icons">help</i>
        </a>
    </div>

    <div id="stats-info" class="modal bottom-sheet">
        <div class="modal-content">
            <h6 class="center blue-text text-darken-1">iVoting Help Center</h6>
            <p>The left side of each poll displays the total number of verified voters. If the poll contains a voter register, <span class="blue-text text-darken-1">iVoting</span> will display in-depth statistics of verified voters against the voter register and the percentage totals. Additionally <span class="blue-text text-darken-1">iVoting</span> displays the daily verification numbers below the verification data.</p>
            <p>The right side of each poll displays displays the voting data i.e the number of voters who have voted against the total number of verified voters.</p>
        </div>
        <div class="modal-footer">
            <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-1">Close</a>
        </div>
    </div>
</template>

<script>
    import M from 'materialize-css'
    export default {
        mounted(){
            M.AutoInit();
        }
    }
</script>

<style scoped>

</style>