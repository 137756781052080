<template>
    <section>
        <div v-if="resLoader" class="center">
            <loader-primary />
        </div>
        <div v-else>
            <div v-if="resStats.length">
                <div v-for="resStat in resStats" :key="resStat">
                    <p class="card-title"><small>{{ resStat.b }}</small> / <span>{{ resStat.c }}</span></p>
                    <section>
                        <div v-if="resStat.b === 0 && resStat.c === 0">
                            <p>0 <small>%</small></p>
                        </div>
                        <div v-else>
                            <p>{{ (((+resStat.b) / resStat.c) * 100).toFixed(1) }} <small>%</small></p>
                        </div>
                    </section>
                    <div class="progress">
                        <div v-if="(((+resStat.b) / resStat.c) * 100).toFixed(1) < 25">
                            <div class="determinate red darken-4" :style="{width: ((((+resStat.b) / resStat.c) * 100).toFixed(1)+'%')}"></div>
                        </div>
                        <div v-else-if="(((+resStat.b) / resStat.c) * 100).toFixed(1) >= 25 && (((+resStat.b) / resStat.c) * 100).toFixed(1) < 50">
                            <div class="determinate yellow lighten-1" :style="{width: ((((+resStat.b) / resStat.c) * 100).toFixed(1)+'%')}"></div>
                        </div>
                        <div v-else-if="(((+resStat.b) / resStat.c) * 100).toFixed(1) >= 50 && (((+resStat.b) / resStat.c) * 100).toFixed(1) < 75">
                            <div class="determinate blue darken-1" :style="{width: ((((+resStat.b) / resStat.c) * 100).toFixed(1)+'%')}"></div>
                        </div>
                        <div v-else>
                            <div class="determinate teal darken-1" :style="{width: ((((+resStat.b) / resStat.c) * 100).toFixed(1)+'%')}"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ resMessage }}</p>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import M from 'materialize-css';
import LoaderPrimary from './LoaderPrimary.vue';
export default {
    props: {
        selectedPoll: {
            type: String,
            required: true,
        },
        selectedPollName: {
            type: String,
            required: true,
        },
        selectedInterval: {
            type: Number,
            required: true,
        },
    },
    data(){
        return{
            resLoader: true,
            resMessage: null,
            resStats: [],
            modal_trigger: '#i'+this.selectedPoll,
            modal_id: 'i'+this.selectedPoll,
            mInterval: (this.selectedInterval / 60000),
        }
    },
    methods: {
        hotReload(){
            setInterval( async () => {
                let data = new FormData()
                data.append('x', this.selectedPoll);
        
                try {
                    const response = await axios.post('https://a.ivoting.co.ke/SvnYBAbxslJHABV/wallabee', data)
                    let returnCondition = response.data.x
                    let returnMessage = response.data.y
            
                    if (returnCondition === 1) {
                        this.resLoader = false
                        this.resMessage = null
                        this.resStats = response.data.z
                    } else {
                        this.resLoader = false
                        this.resMessage = returnMessage
                        this.resStats = []
                    }
                } catch (error) {
                    this.resLoader = false
                    this.resStats = []
                    this.resMessage = 'Network Error. Try Again'
                    //console.log(error);
                }
            }, this.selectedInterval);
        },

        async getResultsData(){
            let data = new FormData()
            data.append('x', this.selectedPoll);
    
            try {
                const response = await axios.post('https://a.ivoting.co.ke/SvnYBAbxslJHABV/wallabee', data)
                let returnCondition = response.data.x
                let returnMessage = response.data.y
        
                if (returnCondition === 1) {
                    this.resLoader = false
                    this.resMessage = null
                    this.resStats = response.data.z
                    this.hotReload();
                } else {
                    this.resLoader = false
                    this.resMessage = returnMessage
                    this.resStats = []
                }
            } catch (error) {
                this.resLoader = false
                this.resStats = []
                this.resMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
    },
    mounted(){
        M.AutoInit();
        this.getResultsData();
        //console.log(this.mInterval);
    },
    components: { LoaderPrimary, }
}
</script>

<style>

</style>